import React from 'react';
import {
    Card,
    CardContent,
    CardMedia,
    CardHeader,
    Divider,
    Grid,
    Typography
} from '@material-ui/core';

const NextEvent = () => {
    return (
        <div className="testimonial-area testimonial-style-1 pb--110">
            <div className="container" style={{marginBottom: 50}}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title mb--10 mb_sm--50 mb_md--50">
                            <h3 className="title">Next Event:</h3>
                        </div>
                    </div>
                </div>
                <div>
                    <Card style={{ backgroundColor: '#575757' }}>
                        <CardContent>
                            <Grid container spacing={8}>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="h3">Location:</Typography>
                                    <Divider 
                                    style={{
                                        marginTop: 5,
                                        marginBottom: 5,
                                        height: 8,
                                        //backgroundColor: 'black'
                                    }}/>
                                    <Grid 
                                    container
                                    direction="column"
                                    justify="flex-end"
                                    alignItems="flex-start"
                                    spacing={2}
                                    >
                                        <Grid item>
                                            <Typography variant="h4">City: Noblesville</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4">State: Indiana</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4">Time: TBD</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4">Address: TBD</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid container 
                                            direction="row"

                                            spacing={2}>
                                                <Grid item xs={12} md={3}>
                                                    <Typography variant="h4">Guests:</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Typography variant="h5">This is invite only.
                                             Attendees will be communicated with directly via 
                                             phone or email. Must be registered through form above</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Typography variant="h3">Format: Small Group, Invite Only</Typography>
                                    <Divider 
                                    style={{
                                        marginTop: 5,
                                        marginBottom: 5,
                                        height: 8,
                                        //backgroundColor: 'black'
                                    }}/>
                                    <Typography variant="h4" style={{marginBottom: 10}}>
                                        This will be a "Fireside Chat" around a campfire/firepit. The goal
                                        is to bring a diverse set of perspectives to the question: "What is the 
                                        biggest problem we face?" The follow-up to that will be "What can we individually 
                                        and collectively do about that?" Speak-E-Z is designed to make sure we not only 
                                        identify problems, but take active, community oriented, approaches to solving them.
                                    </Typography>
                                    <Typography variant="h5">
                                        With these events we dare to have fearless conversations without fear of 
                                        retaliation by a mob. Here, we courageously explore ideas and 
                                        solutions. WE DO NOT BELIEVE IN SILENCING! The best antidote to bad ideas, are 
                                        good ideas.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    )

}

export default NextEvent;