import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { FiXOctagon , FiSunrise, FiEye } from "react-icons/fi";
import Player from './player';

import {
    Grid,
    Typography,
    Divider,
} from '@material-ui/core'


const Why = () => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div>
            <div className="testimonial-area testimonial-style-1 pb--70" data-wow-delay="200ms">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title mb--100 mb_sm--50 mb_md--50">
                                <h3 className="title">Why Speak-E-Z?</h3>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Grid container spacing={6} justify="center" alignItems="flex-start">
                            <Grid item xs={12} md={4} style={{marginBottom: 5}}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    spacing={3}
                                    style={{
                                        marginBottom: 5
                                    }}
                                >
                                    <Grid item>
                                        <FiXOctagon
                                            style={{
                                                fontSize: 44,
                                                color: 'red'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h4" style={{ fontWeight: "bold" }}>Stop Labeling People "Enemies"</Typography>
                                    </Grid>
                                </Grid>
                                <Typography style={{fontSize: 20, textAlign: 'center'}}
                                >
                                    Most Americans have FAR more in common than not. There is no reason to see our 
                                    neighbors as potential or real enemies. Each person is far more interesting 
                                    and complex than their political affiliation, their income, their education, their sexual 
                                    orientation, or their skin color. This is self-evidently clear. The Speak-E-Z project rejects the false 
                                    idea that this country is hopelessly and irrevocably divided. We seek to mend the wounds 
                                    that those elected as leaders have carelessly and irresponsibly inflicted. By refusing to accept the 
                                    premise that we are "enemies", we can come together to solve the problems we see.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={4} style={{marginBottom: 5}}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    spacing={3}
                                    style={{
                                        marginBottom: 5
                                    }}
                                >
                                    <Grid item>
                                        <FiEye
                                            style={{
                                                fontSize: 44,
                                                color: 'green'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h4" style={{ fontWeight: "bold" }}>See Where We Agree</Typography>
                                    </Grid>
                                </Grid>
                                <Typography style={{fontSize: 20, textAlign: 'center'}}>
                                    The best, most effective way, to mend the wounds and division is to recognize  
                                    what problems we agree are important. Problems we can honestly endeavor to remedy. 
                                    If you were to ignore social media and the news, intentionally designed to polarize and enrage, 
                                    what problems would you see right in front of you? Why hasn't anyone tried to solve them? 
                                    How could they be solved? Criticism is easy. Engineering the solution 
                                    is hard. The Speak-E-Z goal is to find points of agreement at the local level that have opportunities for 
                                    real and tangible change, and affect those changes in our day to day lives.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={4} style={{marginBottom: 5}}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    spacing={3}
                                    style={{
                                        marginBottom: 5
                                    }}
                                >
                                    <Grid item>
                                        <FiSunrise
                                            style={{
                                                fontSize: 44,
                                                color: 'orange'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h4" style={{ fontWeight: "bold" }}>Build Together</Typography>
                                    </Grid>
                                </Grid>
                                <Typography style={{fontSize: 20, textAlign: 'center'}}>
                                    Do not look to Washington, or the Governor's Mansion to solve any problems. 
                                    We refuse to take responsibility in our own lives. Whether out of fear of retaliation, a social media mob, or  
                                    losing that which we value, we expect someone else to fix everything and do nothing ourselves. 
                                    This will not work. 
                                    This cannot work. 
                                    Only together can we affect the changes we want to see. There is no reason to fear people hundreds of miles away 
                                    if locally we are strong and cohesive. Strong connections and community are the best defense. 
                                    Together we can build that. Together we will build that. It is hard to be brave, but with Speak-E-Z, you can 
                                    be brave, knowing that others are by your side.
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Why
