import React, { useState } from 'react';
import axios from 'axios'
import { useForm } from "react-hook-form";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogActions,
    Grid,
    Typography,
} from '@material-ui/core';

const ContactForm = ({url}) => {

    const [responseOpen, setResponseOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [errorOpen, setErrorOpen] = useState(false);

    const { register, handleSubmit, errors } = useForm({
		mode: "onBlur"
	})
    const [serverState, setServerState] = useState({
		submitting: false,
		status: null
    });
    
	const [value, setValue] = useState({
		name: '',
		email: '',
		invite: '',
		message: ''
    });
    
    const handleServerResponse = (ok, msg, form) => {
		setServerState({
			submitting: false,
			status: { ok, msg }
		});
		if (ok) {
			form.reset();
			setValue({
				name: '',
				email: '',
				invite: '',
				message: ''
			})
		}
    };
    

    const onSubmit = (data, e) => {
        setLoading(true);
        const form = e.target;
        setServerState({ submitting: true });
        window.gtag("event", 'register', {action: 'Submit Interest'});
		axios({
			method: "post",
			url: `${url}/register`,
			data
		})
			.then(res => {
                console.log(res);
                const resData = res.data;
                console.log(resData);
                if (resData.success !== undefined && resData.success === "Done") {
                    setLoading(false);
                    setResponseOpen(true);
                } else {
                    console.log(resData.err)
                    setLoading(false);
                    setErrorText(resData.err);
                    setErrorOpen(true);
                }
				//handleServerResponse(true, "Thanks! for being with us", form);
			})
			.catch(err => {
				handleServerResponse(false, err.response.data.error, form);
			});
	}

    const isErrors = Object.keys(errors).length !== 0 && true;
	const onChangeHandler = e => {
		setValue({ ...value, [e.target.name]: e.target.value })
	}

    return ( 
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`form-group ${(isErrors && errors.name) ? 'has-error' : ''} ${value.name ? 'has-value' : ''}`}>
                <input 
                    type="text"
                    name="name"
                    id="name"
                    style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}
                    onChange={onChangeHandler}
                    ref={register({
                        required: 'Name Required',
                    })}
                />
                <label htmlFor="name">Name</label>
                {errors.name && <span className="error">{errors.name.message}</span>}
            </div>

            <div className={`form-group ${(isErrors && errors.email) ? 'has-error' : ''} ${value.email ? 'has-value' : ''}`}>
                <input 
                    type="email"
                    name="email"
                    id="email"
                    style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}
                    onChange={onChangeHandler}
                    ref={register({
                        required: 'Email Required',
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "invalid email address"
                        }
                    })}
                />
                <label htmlFor="email">Enter Your Email (We will Reach Out)</label>
                {errors.email && <span className="error">{errors.email.message}</span>}
            </div>

            <div className={`form-group ${(isErrors && errors.message) ? 'has-error' : ''} ${value.message ? 'has-value' : ''}`}>
                <textarea
                    name="message"
                    id="message"
                    style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}
                    onChange={onChangeHandler}
                    ref={register({
                        required: 'Message Required',
                        minLength: { value: 10, message: "Minimum length is 10" }
                    })}
                >
                </textarea>
                <label htmlFor="message">Why are you interested?</label> 
                {errors.message && <span className="error">{errors.message.message}</span>}
            </div>

            <div className="form-submit">
                <button className="rn-button" type="submit" disabled={loading}
                style={{
                    fontWeight: 'bold',
                    fontSize: 22
                }}>
                    {loading && <CircularProgress size={25}/>} {loading ? 'Loading' : 'Register'}
                </button>
                {serverState.status && (
                    <h2 className={`form-output ${!serverState.status.ok ? "errorMsg" : "success"}`}>
                        {serverState.status.msg}
                    </h2>
                )}
            </div>
            <div>
            {responseOpen && (
                <Dialog
                open={responseOpen}
                fullWidth
                maxWidth="sm"
                onClose={() => setResponseOpen(false)}
                style={{
                    backgroundColor: 'grey'
                }}
                >
                    <DialogContent
                        style={{
                            backgroundColor: 'grey'
                        }}>
                        <Grid container spacing={3}>
                            <Grid item>
                            </Grid>
                            <Grid item >
                                <Typography variant="h3" style={{ color: 'white', textAlign: 'center' }}>
                                    Registration Complete! Look for an email 
                                    for next steps! You should receive this email in the next 
                                    few days from info@speak-e-z.com
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions
                        style={{
                            backgroundColor: 'grey'
                        }}
                    >
                        <Button
                        onClick={() => setResponseOpen(false)}
                        variant="contained"
                        size="large"
                        fontSize="large"
                        fullWidth
                        style={{
                            color: 'white',
                            backgroundColor: 'green',
                                fontSize: 20
                            }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {errorOpen && (
                <Dialog
                    open={errorOpen}
                    onClose={() => setErrorOpen(false)}
                    fullWidth
                    maxWidth="sm"
                    style={{
                        backgroundColor:"grey"
                    }}>
                    <DialogContent
                        style={{
                            backgroundColor:"grey"
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item>
                            </Grid>
                            <Grid item >
                                <Typography variant="h3" style={{ color: 'white' }}>
                                    {errorText}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions
                    style={{
                        backgroundColor: 'grey'
                    }}>
                        <Button
                        onClick={() => setErrorOpen(false)}
                        variant="contained"
                        size="large"
                        fontSize="large"
                        fullWidth
                        style={{
                            color: 'white',
                            backgroundColor: 'red',
                                fontSize: 20
                            }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            </div>
        </form> 
    )
}

export default ContactForm;


//Code Stuff

//<div className={`form-group ${''} ${value.invite ? 'has-value' : ''}`}>
//    <input
//        type="text"
//        name="invite"
//        id="invite"
//        style={{
//            color: 'white',
//            fontWeight: 'bold'
//        }}
//        onChange={onChangeHandler}
//    />
//    <label htmlFor="invite">Invite Code</label>
//    {errors.invite && <span className="error">{errors.invite.message}</span>}
//</div>