import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/homedefault/banner";
import About from "../components/homedefault/about";
import Project from "../components/homedefault/project";
import Why from "../components/homedefault/testimonial";
import Service from "../components/homedefault/service";
import Brand from "../components/homedefault/brand";
import NextEvent from '../components/homedefault/nextEvent';
import BlogPost from "../components/blogPost";
import Footer from '../components/footer/footer';
import GoogleAnalytics from '../components/googleAnalytics';
import Contact from "../elements/contact/contact";

const IndexPage = () => (
  <Layout>
    <SEO title="Speak-E-Z" />
    <GoogleAnalytics />
    <Banner />
    <About />
    <Service />
    <div className="testimonial-id" id="why">
      <Why />
    </div>
    <Contact />
    <NextEvent />
    <Footer />
  </Layout>
)
export default IndexPage;